import React from "react"
import styled from "styled-components"

const FrameContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

  /* Then style the iframe to fit in the container div with full height and width */
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`

const Video = ({ videoSrcURL, videoTitle, ...props }) => {
  if (!videoSrcURL.includes("embed")) {
    if (videoSrcURL.includes("tu.be/")) {
      videoSrcURL = `https://www.youtube.com/embed/${
        videoSrcURL.split("tu.be/")[1]
      }`
    } else if (videoSrcURL.includes("?v=")) {
      videoSrcURL = `https://www.youtube.com/embed/${
        videoSrcURL.split("?v=")[1]
      }`
    }
  }
  return (
    <FrameContainer>
      <iframe
        className="responsive-iframe"
        src={videoSrcURL}
        title={videoTitle}
        allow="encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
        width={props.width || "400"}
        height={props.height || "200"}
        loading="lazy"
      />
    </FrameContainer>
  )
}

export default Video
