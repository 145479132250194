import React from "react"
import { graphql, Link } from "gatsby"
import VideoCard from "../components/video-gallery/VideoCard"
import Video from "../components/video-gallery/Video"
import Container from "../components/Container"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import BgImg from "../components/BgImg"

const VideosContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const PageButton = styled.li`
  background-color: #f9feff;
  padding: 10px 25px;
  border-radius: 25px;
  margin: 0 25px;
`

const VideoListTemplate = props => {
  const videos = props.data.allMarkdownRemark.edges

  const { previous, next, currentPage } = props.pageContext

  return (
    <Layout>
      <SEO title="Videos" />
      <Container
        style={{
          background: `#52575a`,
          color: `white`,
        }}
        title={currentPage === 1 && "Video Gallery"}
        whitetitle
      >
        <VideosContainer>
          {videos.map(({ node }, i) => {
            const videoTitle = node.frontmatter.videoTitle
            const videoSrcURL = node.frontmatter.videoSrcURL
            return (
              <VideoCard key={i} title={videoTitle}>
                <Video
                  videoSrcURL={videoSrcURL}
                  videoTitle={videoTitle}
                ></Video>
              </VideoCard>
            )
          })}
        </VideosContainer>

        <ul
          style={{
            display: `flex`,
            flexFlow: `row nowrap`,
            justifyContent: `space-between`,
            alignItems: `center`,
            listStyle: `none`,
            zIndex: 15,
            maxWidth: "700px",
            width: "100%",
          }}
        >
          {previous && (
            <Link to={previous} rel="prev">
              <PageButton>← Page {currentPage - 1}</PageButton>
            </Link>
          )}
          {!previous && <div />}

          {next && (
            <Link to={next} rel="next">
              <PageButton>Page {currentPage + 1} →</PageButton>
            </Link>
          )}

          {!next && <div />}
        </ul>

        <BgImg />
      </Container>
    </Layout>
  )
}

export default VideoListTemplate

export const videoListQuery = graphql`
  query videoListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { templateKey: { eq: "video" } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          frontmatter {
            videoTitle
            videoSrcURL
          }
        }
      }
    }
  }
`
