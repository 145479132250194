import React from "react"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px;

  -webkit-box-shadow: 0 0 15px 15px rgba(87, 87, 89, 1);
  box-shadow: 0 0 15px 15px rgba(87, 87, 89, 1);

  border-radius: 25px;
  max-width: 700px;
  width: 100%;
  background-color: rgba(50, 50, 50, 1);
  background-color: #f9feff;
  z-index: 15;
  color: black;
  margin-top: 35px;
  padding-bottom: 25px;

  h2 {
    color: rgb(87, 89, 89);
  }
`

const VideoCard = ({ children, title }) => (
  <Container>
    <h2>{title}</h2>
    {children}
  </Container>
)

export default VideoCard
